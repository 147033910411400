
input.specialinput{
  color: #140B2Dff;
  background-color: #025B79ff;
  border: none;
}

input.specialinput:hover{
  color: #140B2Dff;
  background-color: #5C3145ff;
  border: none;
}

input.specialinput[type=text], select {
  width: 80vw;
  font-size: 15px;
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #5C3145ff;
  border-radius: 4px;
  box-sizing: border-box;
}

input.specialinput[type=submit] {
  width: 80vw;
  font-size: 15px;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input.specialinput[type=submit]:hover {
  background-color: #5C3145ff;
}

div.containercrud {
  border-radius: 5px;
 
  padding: 20px;
  margin: auto;
  width: 80vw;
  padding: 10px;
  overflow-x: auto;
}
.tablethingdiv2{
  overflow-x:auto;
  width: 100%;
  height: 100%;
}
