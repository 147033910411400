video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

headervideo {
  object-fit: cover;
  width: 100%;
  height: 100%;

}

:root {
  --bg: #101730ff;
  --bg-accent: #1B2524ff;
  --bg-accent2: #243232ff;
  --bg-accent3: #2E5252ff;
  --text-color: #AEBEBEff;
  --bg-red1: #EE0527ff;
  --bg-red2: #E0012Aff;
  --bg-red3: #E5383B;
  --nav-size: 60px;
  --border-radius: 8px;
  --speed: 500ms;
  --bg-2: #3F646Dff;
  --oxford-blue: #101730ff;
  --deep-space-sparkle: #3F646Dff;
  --dark-slate-gray: #2E5252ff;
  --outer-space-crayola: #243232ff;
  --dark-jungle-green: #1B2524ff;
  /* CSS HEX */
  --medium-candy-apple-red: #E0012Aff;
  --red-pigment: #EE0527ff;
  --old-mauve: #5C3145ff;
  --dark-purple: #140B2Dff;
  --space-cadet: #212C50ff;
  /* CSS HEX */
  --cg-blue: #19819Fff;
  --outer-space-crayola: #263434ff;
  --sonic-silver: #787170ff;
  --ash-gray: #AEBEBEff;
  --blue-sapphire: #025B79ff;
}

img {
  border: 1px solid var(--bg);
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 var(--ash-gray);
  max-width: 100%;
  height: auto;
}

.img-2 {


  box-shadow: 0 4px 8px 0 var(--bg-accent), 0 6px 20px 0 var(--ash-gray);
  max-width: 100%;
  height: auto;
}

.temp-fix {
  display: none;
}

.dsaMenu{
  width: 100%;
  background-color: #025B79ff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}
.dsaMenu2{
  width: 100%;
  background-color: #19819Fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 35px;
  margin: 5px;
  border: 2px solid white;

  
}

.dsaMenu2 > ul {
 
}


.dsaMenu2 > ul > li{
 
  padding: 10px;
}

.dsaMenu3{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  margin: 10px;
 
}
.dsaMenu2 > p{
  fontWeight: 900;
  line-spacing: 10px;
  color: white;

}
.dsaMenu > h4 {
  background-color: #AEBEBEff;
  color: black;
  border: 1px solid black;
  padding: 10px;
}

p {
  white-space:break-spaces;
  font-size: auto;
  line-height: auto;
  hyphens: auto;
}

.Iam {
  cursor: url('/src//icons/Bmocute.png'), auto;
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  border: 1px solid #FFFFFF;
  background-image: url('https://media.giphy.com/media/UzrA4AE4CGzoC0NmeB/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0px;
  border-radius: 5px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.Iam b {
  float: right;
  overflow: hidden;
  position: relative;
  height: 32px;
  top: -10px;
  border-top: 2px solid #2E5252ff;
  border-bottom: 2px solid #2E5252ff;
}

.Iam .innerIam {
  display: inline-block;
  color: var(--text-color);
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  font-size: large;

  /*animation*/
  -webkit-animation: move 8s;
  -moz-animation: move 8s;
  -ms-animation: move 8s;
  -o-animation: move 8s;
  animation: move 8s;
  /*animation-iteration-count*/
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  /*animation-delay*/
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -ms-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes move {
  0% {
    top: -15px;
  }

  20% {
    top: -25px;
  }

  40% {
    top: -48px;
  }

  60% {
    top: -70px;
  }

  80% {
    top: -90px;
  }

  100% {
    top: -113px;
  }

}

@-moz-keyframes move {
  0% {
    top: -15px;
  }

  20% {
    top: -25px;
  }

  40% {
    top: -48px;
  }

  60% {
    top: -70px;
  }

  80% {
    top: -90px;
  }

  100% {
    top: -113px;
  }

}

@-o-keyframes move {
  0% {
    top: -15px;
  }

  20% {
    top: -25px;
  }

  40% {
    top: -48px;
  }

  60% {
    top: -70px;
  }

  80% {
    top: -90px;
  }

  100% {
    top: -113px;
  }

}

@keyframes move {
  0% {
    top: 0px;
  }

  20% {
    top: -28px;
  }

  40% {
    top: -58px;
  }

  60% {
    top: -88px;
  }

  80% {
    top: -118px;
  }

  100% {
    top: -148px;
  }

}

.subheader1 {
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/8AfXiDla31kqUI2VQD/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.subheader2 {
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/1n912mTRT08Zn0yGqD/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.subheader2body {

  background-color: #2E5252ff;
}

.subheader3 {
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/3Dpb6nPAPObuU46ZDe/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.subheader3body {

  
}

.subheader4 {
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/3roOFtzGnGhsNwdc03/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.subheader4body {

  background-color: #2E5252ff;
}

.subheader5 {
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/Cae7w9PrSYBLfhVfD0/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.subheader6 {
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/9J7iiYCow3RJFxUyT8/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.subheader7 {
  cursor: url('/src//icons/Koya.png'), auto;
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/3Fpe0mIR5Y7MyCcxse/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.subheader8 {
  cursor: url('/src//icons/phone2.png'), auto;
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;

  background-size:cover;
  background-repeat:no-repeat;
  background-position:top;
}

.subheader9 {
  cursor: url('/src//icons/favicon.ico'), auto;
  margin: auto;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  text-align: center;
  background-image: url('https://media.giphy.com/media/xULW8LuH8tqB4H0Egg/giphy.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.spacer {
  display: block;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-bottom: none;
  border-top-width: 1px;
  background-color: #243232ff;
  border-radius: 5px;
}

.spacer2 {
  display: block;
  margin-top: .2rem;
  margin-bottom: .2rem;
  border-bottom: none;
  border-top-width: 1px;
}



.special-link:hover {
  box-shadow: 20px 0px 50px 0 rgba(111, 40, 245, 0.5), 0 6px 20px 0 rgba(112, 25, 251, 0.19);
  transition: box-shadow 2s;
}

.special-link:visited {
  box-shadow: 20px 0px 50px 0 rgb(144, 0, 180), 0 6px 20px 0 rgba(192, 32, 197, 0.822);
  transition: box-shadow 2s;
}

.end-div {
  height: auto;
  width: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-family: 'Syne Mono', monospace;
  padding: 5px;
  text-align: center;
  border: 1px solid var(--bg-accent);
  transition: border 3s;
  background-image: url("/src//icons//bg4.png");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

.end-div2 {
  height: auto;
  width: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-family: 'Syne Mono', monospace;
  padding: 5px;
  text-align: center;
  border: 1px solid var(--bg-accent);
  transition: border 3s;
  background-image: url("/src//icons//bg5.png");
  background-position: top;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  background-position: bottom;
}

.end-div3 {
  height: auto;
  width: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-family: 'Syne Mono', monospace;
  padding: 1px;
  text-align: center;
  border: 2px solid var(--bg-accent);
  transition: border 3s;
  background-color: var(--cg-blue);
  background-position: top;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

.end-div4 {
  height: auto;
  width: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-family: 'Syne Mono', monospace;
  padding: 1px;
  text-align: center;
  border: 1px solid var(--sonic-silver);
  backgroundColor: 'black';

}

.end-div5 {
  height: auto;
  width: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  font-family: 'Syne Mono', monospace;
  padding: 0px;
  text-align: center;
  transition: border 3s;
}



.end-div-update {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  background-color: #161A1D;
  padding: 20px;

}

.end-div-update:hover {
  background-color: #320304;

}

.coffeeBtn {
  margin: 5px;
  height: auto;
  width: auto;
  display: flexbox;
  justify-content: center;
  background-color: var(--bg);
  opacity: 1;
}

.coffeeBtn:hover {
  height: auto;
  width: auto;
  display: flexbox;
  justify-content: center;
  background-color: white;
}

.coffeeBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.coffeeBtn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -50px;
  transition: 0.5s;
}

.coffeeBtn:hover span {
  padding-right: 05px;
}

.coffeeBtn:hover span:after {
  opacity: 1;
  right: 0;
}

.contactCT {
  width: 99%;
  padding: 10px;
  margin: 5px;
  height: auto;
}

.fa-instagram {
  padding: 5px;
  margin: 10px;
  font-size: 30px;
  width: auto;
  text-align: center;
  text-decoration: none;
  border-radius: 70%;
  background: linear-gradient(180deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #ee7752, #F77737, #FCAF45, #FFDC80);
  color: white;
  border: 2px solid white;
}

.fa-instagram:hover {
  font-size: 40px;
  transition: ease-in-out;
}

.fa-linkedin {
  padding: 5px;
  margin: 10px;
  font-size: 30px;
  width: auto;
  text-align: center;
  text-decoration: none;
  border-radius: 70%;
  background: #2867B2;
  color: white;
  border: 2px solid #4B0506;
}

.fa-linkedin:hover {
  font-size: 40px;
  transition: ease-in-out;
}

.fa-twitter {
  padding: 5px;
  margin: 5px;
  font-size: 30px;
  width: auto;
  text-align: center;
  text-decoration: none;
  border-radius: 70%;
  background: white;
  color: #1DA1F2;
  border: 1px solid black;
  border-radius: 10px;
}

.fa-twitter:hover {
  font-size: 40px;
  transition: ease-in-out;
}


.gio-main {
  
  transition: border 3s;
  text-align: center;
  padding:2px;
}

.gio-main:hover {

  transition: border 3s;
}

.gio-container {
  padding: 2px;
  margin: auto;
  border-radius: 5px;
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px var(--bg-1);
  object-fit: contain;
  text-align: center;
  border: 1px solid var(--ash-gray);
  transition: border 3s;
  background-image: url("/src//icons//bg1.png");
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
}

.gio-container:hover {
  border: 1px solid var(--oxford-blue);
  transition: border 3s;
}

.gio-container>h1 {
  background: #101730ff;
  color: var(--ash-gray);
  font-size: 50px;
  font-weight: 900;
  margin-top: auto;
  padding: 10px;
  font-family: 'Bungee Hairline', cursive;
}

.gio-container>h4 {
  background: var(--bg);
  color: var(--ash-gray);
  margin: 5px;
  padding: 10px;
  font-size: 40px;
  font-family: 'Exo', sans-serif;
}

.gio-container>p {
  margin: 4px;
  color: var(--ash-gray);
  background: var(--bg);
  font-size: auto;
  font-family: 'Syne Mono', monospace;
  padding: 10px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.gio-container>ul {

  color: var(--text-color);
  font-size: 15px;
  padding: 30px;
  align-items: left;

  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container>li {

  color: var(--text-color);
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container>dl {

  color: var(--text-color);
  font-size: 20px;
  list-style-type: circle;
  align-items: left;

  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container>dt {

  color: var(--text-color);
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container>dd {

  color: var(--text-color);
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-special {
  
  margin: 10px;
  height: auto;
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  background-color: none;
  border-radius: 5px;
  transition: 3s background-color;
}

.gio-container-special:hover {
  background-color: var(--bg-2);
  transition: 2s background-color;
}


.gio-container-2 {
  padding: 20px;
  height: auto;
  border-radius: 5px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  background: linear-gradient(45deg, var(--bg-accent3), var(--bg), var(--bg-2));
  text-align: center;
  border: 1px solid var(--bg-2);
  transition: border 3s;
}

.gio-container-2:hover {
  border: 1px solid var(--ash-gray);
  transition: border 3s;
}

.gio-container-2>h1 {
  color: var(--text-color);
  font-size: 40px;
  border: 25px outset var(--bg-accent);
  animation: flickerenddiv3 30s linear infinite;
  font-family: 'Bungee Hairline', cursive;
  padding: 10px;
}

.gio-container-2>h4 {
  margin: 14px;
  color: var(--ash-gray);
  background: var(--bg-2);
  font-size: 50px;
  font-family: 'Exo', sans-serif;
  padding: 5px;
}

.gio-container-2>p {
  margin: 5px;
  color: var(--ash-gray);
  background: var(--bg);
  font-size: 20px;
  font-family: 'Syne Mono', monospace;
}

.gio-container-2>ul {
  padding: 30px;
  margin: 5px;
  color: var(--text-color);
  font-size: 15px;

  border: 2px solid var(--bg-accent3);
  border-radius: 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-2>li {
  padding: 50px;
  margin: 5px;
  color: var(--text-color);
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-3 {
  height: auto;
  width: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  background: linear-gradient(145deg, var(--bg-accent3), var(--bg), var(--bg-2));
  text-align: center;
  border: 1px solid var(--deep-space-sparkle);
  transition: border 3s;
  padding: 100px;
}

.gio-container-3:hover {
  border: 1px solid var(--bg-accent2);
  transition: border 3s;
}

.gio-container-3>h1 {
  color: var(--text-color);
  font-size: auto;
  border: 25px outset var(--bg-accent);
  animation: flickerenddiv3 30s linear reverse infinite;
  font-family: 'Bungee Hairline', cursive;
}

.gio-container-3>h4 {
  margin: 5px;
  color: var(--ash-gray);
  background: var(--bg-2);
  font-size: 40px;
  font-family: 'Exo', sans-serif;
  padding: 10px;
}

.gio-container-3>p {
  margin: 5px;
  color: var(--ash-gray);
  background: var(--bg);
  font-size: 20px;
  font-family: 'Syne Mono', monospace;
}

.gio-container-3>ul {
  padding: 20px;
  margin: 5px;
  color: var(--text-color);
  font-size: 15px;

  border: 2px solid var(--bg-accent3);
  border-radius: 15px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-3>li {
  padding: 10px;
  margin: 10px;
  color: var(--text-color);
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-4 {
  padding: 100px;
  height: auto;
  border-radius: 5px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  background: linear-gradient(300deg, var(--bg-accent3), var(--bg), var(--bg-2));
  text-align: center;
  border: 1px solid var(--bg-2);
  transition: border 3s;
}

.gio-container-4:hover {
  border: 1px solid var(--ash-gray);
  transition: border 3s;
}

.gio-container-4>h1 {
  color: var(--text-color);
  font-size: auto;
  border: 25px outset var(--bg-accent);
  animation: flickerenddiv3 30s linear reverse infinite;
  font-family: 'Bungee Hairline', cursive;
  padding: 20px;
  margin: 5px;
}

.gio-container-4>h4 {
  margin-top: 16px;
  color: var(--ash-gray);
  background: var(--bg-2);
  font-size: 40px;
  font-family: 'Exo', sans-serif;
  padding: 10px;
  margin: 5px;
}

.gio-container-4>p {
  margin-top: 8px;
  color: var(--ash-gray);
  background: var(--bg);
  font-size: 19px;
  font-family: 'Syne Mono', monospace;
  padding: 10px;
  margin: 5px;
}

.gio-container-4>ul {
  padding: 30px;
  margin: 5px;
  color: var(--text-color);
  font-size: 15px;

  border: 2px solid var(--bg-accent3);
  border-radius: 10px;

  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-4>li {

  color: var(--text-color);
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.gio-container-5 {
  padding: 100px;
  height: auto;
  border-radius: 5px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  background: linear-gradient(250deg, var(--bg-accent3), var(--bg), var(--bg-2));
  text-align: center;
  border: 1px solid var(--bg-2);
  transition: border 3s;
}

.gio-container-5:hover {

  transition: border 3s;
}

.gio-container-5>h1 {
  color: var(--text-color);
  font-size: auto;
  border: 25px outset var(--bg-accent);
  animation: flickerenddiv3 30s linear reverse infinite;
  font-family: 'Bungee Hairline', cursive;
  padding: 10px;
  margin: 5px;
}

.gio-container-5>h4 {
  margin-top: 16px;
  color: var(--ash-gray);
  background: var(--bg-2);
  font-size: 38px;
  font-family: 'Exo', sans-serif;
  padding: 10px;
  margin: 5px;
}

.gio-container-5>p {
  margin: 8px;
  color: var(--ash-gray);
  background: var(--bg);
  font-size: 19px;
  font-family: 'Syne Mono', monospace;
  padding: 20px;
}

.twitterframe {
  align-self: center;
 
  width: 100%;
  height: 59vh;
  margin: 10px;
  min-width: auto;
  max-width: 90vw;
  background-color: var(--bg-2);
  transition: 2s border;
  border: none;
  background: none;
}

.twitterframe:hover {
  background-color: var(--bg-accent2);
  transition: 2s background-color;
  transition: 2s border;
  
}

.tiktokframe {
  width: 50%;
  height: 77vh;
  min-width: auto;
  max-width: 90vw;
  background-color: none;
  transition: 2s border;
  
}

.tiktokframe:hover {
  background-color: var(--bg-accent2);
  transition: 2s background-color;
  transition: 2s border;
  
}

.instaframe {
  align-items: center;
  display: block;
  width: 50%;
  height: 180vh;
  background-color: none;
  transition: 2s background-color;
  transition: 2s border;
  
}

.instaframe:hover {
  background-color: var(--bg-accent2);
  transition: 2s background-color;
  transition: 2s border;
  
}


/*Horizontal Responsiveness*/
@media screen and (max-width: 960px) {
  .gio-container>h1 {
    color: var(--text-color);
    font-size: 60px;
    margin-top: auto;
  }

  .gio-container-2>h1 {
    font-size: 60px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-2>ul {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-2>li {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>h1 {
    font-size: 60px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-3>ul {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>li {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>h1 {
    font-size: 60px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-4>ul {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>li {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>h1 {
    font-size: 60px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-5>ul {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>li {

    color: var(--text-color);
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .end-div {
    height: auto;
    width: auto;
    border: 1px solid var(--bg-accent3);
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .gio-container {
    height: auto;
    width: auto;
  }

  .gio-container>h1 {
    color: var(--text-color);
    font-size: 20px;
    margin-top: auto;
  }

  .gio-container>h4 {
    font-size: auto;
    color: red;
  }

  .gio-container>p {
    font-size: auto;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 90%;
  }

  .gio-container-2 {
    padding: 0px;

  }

  .gio-container-2>h1 {
    font-size: 25px;
    border: 2px outset var(--bg-accent);
  }

  .gio-container-2>h4 {
    font-size: 20px;
  }

  .gio-container-2>p {
    font-size: 15px;
  }

  .gio-container-2>ul {
    color: var(--text-color);
    font-size: 15px;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-2>li {
    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3 {
    padding: 1px;
  }

  .gio-container-3>h1 {
    font-size: auto;
    border: 1px outset var(--bg-accent);
  }

  .gio-container-3>h4 {
    font-size: 20px;
  }

  .gio-container-3>p {
    font-size: 15px;
  }

  .gio-container-3>ul {
    color: var(--text-color);
    font-size: 15px;
    padding: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>li {
    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4 {
    padding: 1px;
  }

  .gio-container-4>h1 {
    font-size: auto;
    border: 1px outset var(--bg-accent);
  }

  .gio-container-4>h4 {
    font-size: 20px;
  }

  .gio-container-4>p {
    font-size: 15px;
  }

  .gio-container-4>ul {
    color: var(--text-color);
    font-size: 15px;
    padding: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>li {
    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5 {
    padding: 1px;

  }

  .gio-container-5>h1 {
    font-size: auto;
    border: 1px outset var(--bg-accent);
  }

  .gio-container-5>h4 {
    font-size: 20px;
  }

  .gio-container-5>p {
    font-size: 15px;
    color: red;
  }

  .gio-container-5>ul {
    color: var(--text-color);
    font-size: 15px;
    padding: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>li {
    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .end-div {
    font-size: auto;
    height: auto;
    width: auto;
    border: 1px solid var(--bg-accent3);
    display: flex;
    justify-content: center;
  }

  .twitterframe {
    width: 100%;
    height: 49vh;
    min-width: auto;
    max-width: 99vw;
    border: 1px solid var(--bg-accent2);
  }

  .tiktokframe {
    width: 100%;
    height: 80vh;
    min-width: auto;
    max-width: 99vw;
    border: 1px solid var(--bg-accent2);
  }

  .instaframe {
    align-items: center;
    display: block;
    width: 100%;
    height: 79vh;
    border: 1px solid var(--bg-accent2);
  }

  .gio-container-special {
    padding: 5px;
    margin: 0px;
    height: auto;
    background-color: #19819Fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    border: 1px solid var(--bg-accent3);
  }

}

@media screen and (max-width: 360px) {
  .gio-container>h1 {
    color: var(--text-color);
    font-size: 20px;
    margin-top: auto;
  }
}

/*Vertical Responsiveness*/
@media screen and (max-height: 900px) {
  .gio-main {
  

  }

  .hero-btns {
    margin-top: auto;
  }

  .hero-btns .btn {
    margin: 6px;
  }

  .fa-play-circle {
    margin-left: 4px;
  }

  .gio-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background: rgba(0, 0, 0, 0.1);

  }

  .gio-container-2 {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(45deg, var(--bg-accent3), var(--bg), var(--bg-2));

  }

  .gio-container-3 {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(145deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container-4 {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(300deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container-5 {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(250deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container>h1 {
    color: var(--text-color);
    font-size: auto;
    margin-top: auto;
  }

  .gio-container>h4 {
    margin-top: auto;
    color: var(--text-color);
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container>p {
    color: var(--text-color);
    font-size: auto;
    background: rgba(36, 50, 50, 0.679);
    font-family: Roboto, "Helvetica Neue", ;
    border: 2px solid #0b090aa8;
  }

  .gio-container-2>h1 {
    font-size: 40px;
    border: 25px outset var(--bg-accent);
  }

  .gio-container-2>p {
    color: #AEBEBEff;
    font-size: auto;
    background: #0b090adf;
    font-family: Roboto, "Helvetica Neue", ;
    border: 2px solid #0b090aa8;
  }

  .gio-container-2>ul {

    color: var(--text-color);
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-2>li {

    color: var(--text-color);
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>h1 {
    font-size: 50px;
    border: 25px outset var(--bg-accent3);
  }

  .gio-container-3>p {
    color: #AEBEBEff;
    font-size: auto;
    background: #0b090adf;
    font-family: Roboto, "Helvetica Neue", ;
    border: 2px solid #0b090aa8;
  }

  .gio-container-3>ul {

    color: var(--text-color);
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>li {

    color: var(--text-color);
    font-size: auto;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>h1 {
    font-size: 40px;
    border: 25px outset var(--bg-accent2);
  }

  .gio-container-4>p {
    color: #AEBEBEff;
    font-size: auto;
    background: #0b090adf;
    font-family: Roboto, "Helvetica Neue", ;
    border: 2px solid #0b090aa8;
  }

  .gio-container-4>ul {

    color: var(--text-color);
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>li {

    color: var(--text-color);
    font-size: 40px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>h1 {
    font-size: 25px;
    border: 25px outset var(--bg-2);
  }

  .gio-container-5>p {
    color: #AEBEBEff;
    font-size: auto;
    background: #0b090adf;
    font-family: Roboto, "Helvetica Neue", ;
    border: 2px solid #0b090aa8;
  }

  .gio-container-5>ul {

    color: var(--text-color);
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>li {

    color: var(--text-color);
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .end-div {
    height: auto;
    width: auto;
    border: 1px dashed rgb(255, 139, 139);
    display: flex;
    justify-content: center;
  }

}

@media screen and (max-height: 360px) {
  .gio-main {
   
  }

  .hero-btns {
    margin-top: 32px;
    display: none;
  }

  .hero-btns .btn {
    margin: 6px;
  }

  .fa-play-circle {
    margin-left: 4px;
  }

  .gio-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .gio-container-2 {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(45deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container-3 {
    height: 200vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(145deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container-4 {
    height: 200vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(300deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container-5 {
    height: 220vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background: linear-gradient(250deg, var(--bg-accent3), var(--bg), var(--bg-2));
  }

  .gio-container>h1 {
    color: var(--text-color);
    font-size: auto;
    margin-top: auto;
  }

  .gio-container>h4 {
    margin-top: auto;
    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container>p {
    margin-top: auto;
    color: var(--text-color);
    font-size: auto;
    background: rgba(0, 0, 0, 0.1);

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-2>h1 {
    font-size: 30px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-2>p {
    margin-top: 8px;
    color: var(--bg-red2);
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-2>ul {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-2>li {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>h1 {
    font-size: 30px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-3>p {
    margin-top: 8px;
    color: #AEBEBEff;
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>ul {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-3>li {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>h1 {
    font-size: 30px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-4>p {
    margin-top: 8px;
    color: #AEBEBEff;
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>ul {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-4>li {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>h1 {
    font-size: 30px;
    border: 25px outset var(--bg-accent);

  }

  .gio-container-5>p {
    margin-top: 8px;
    color: var(--bg-red2);
    font-size: 12px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>ul {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .gio-container-5>li {

    color: var(--text-color);
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .end-div {
    height: auto;
    font-size: 12px;
    width: 94%;
    border: 1px dashed rgb(255, 139, 139);
    display: flex;
    justify-content: center;
  }

}


@keyframes bgchange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes flickerenddiv {
  0% {
    opacity: 0.4;
  }

  5% {
    opacity: 0.5;
  }

  10% {
    opacity: 0.6;
  }

  15% {
    opacity: 0.85;
  }

  25% {
    opacity: 0.5;
  }

  30% {
    opacity: 1;
  }

  35% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.25;
    height: 51px;


  }

  45% {
    opacity: 0.5;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: 0.85;
  }

  80% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flickerenddiv3 {
  0% {
    border: inset var(--bg-accent);
  }

  5% {
    border: outset var(--bg-accent);
  }

  10% {
    border: inset var(--bg-accent);
  }

  15% {
    border: outset var(--bg-accent);
  }

  25% {
    border: inset var(--bg-accent);
  }

  30% {

    border: outset var(--bg-accent);
  }

  35% {
    border: inset var(--bg-accent);

  }

  40% {
    border: outset var(--bg-accent);

  }

  45% {
    border: inset var(--bg-accent);

  }

  60% {
    border: outset var(--bg-accent);
  }

  70% {
    border: inset var(--bg-accent);
  }

  80% {
    border: outset var(--bg-accent);
  }

  90% {
    border: inset var(--bg-accent);
  }

  100% {
    border: outset var(--bg-accent);
  }
}