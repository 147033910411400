:root {
    --primary: #A4161A;
    --bg: #0B090A;
    --bg-accent: #660708;
    --bg-accent2: #4B0506;
    --bg-accent3: #320304;
    --text-color: #F5F3F4;
    --bg-red1: #A4161A;
    --bg-red2: #BA181B;
    --bg-red3: #E5383B;
  }
  
  .btn {
    padding: 8px 20px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
  }
  
  .btn--outline {
    background-color: transparent;
    color: var(--text-color);
    padding: auto;
    transition: all 2s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 2s ease-in;
   
    color: var(--bg-red1);
    transition: 1s ease-out;
    padding: auto;
    font-size: 20px;
  }