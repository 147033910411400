.tablethingdiv{
    overflow-x:auto;
    width: 100%;
    height: 100%;
}
th:nth-child(odd):hover{
    color:#AEBEBEff;
    border: 1px solid #140B2Dff;
    background-color: #5C3145ff;
}
th:nth-child(even):hover{
    color:#AEBEBEff;
    border: 1px solid #140B2Dff;
    background-color: #5C3145ff;
}
td:nth-child(even):hover{
    color:#101730ff;
    border: 1px solid #140B2Dff;
}
td:nth-child(odd):hover{
    color:#101730ff;
    border: 1px solid #140B2Dff;
}

th{
    background-color: #212C50ff;
    border-top:none;
    font-size: large;
    border: 1px solid #140B2Dff;
   
}
td,th{
   
    border-top: 1px solid #787170ff;
}

td{
    border-top: 1px solid #140B2Dff;
    border-right:1px solid #787170ff;
}

table{
    color: #AEBEBEff;
    background-color: #025B79ff;
    border: 2px solid #787170ff;
    border-collapse: collapse;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: auto;
    white-space: nowrap;
    border-radius: 4px;
    border-spacing: 0px;
    text-align: left;
    width: 100%;
    height: 100%;
    overflow-x:auto;  
}

tr:nth-child(even){
    background-color: #19819Fff;
}
tr:nth-child(odd){
    background-color: #025B79ff;
}
th:nth-child(odd){
    color: #AEBEBEff;
}
th:nth-child(even){
    color: #AEBEBEff;
}

tr:hover{
    background-color: #787170ff;
    color: #101730ff;
    box-shadow: #787170ff 0px 0px 9px 0px;
}
tr{
    box-shadow: 0px 0px 9px 0px;
}
