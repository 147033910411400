code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #aebebeff;
}

:root {
  --bg: #101730ff;
  --bg-accent: #1b2524ff;
  --bg-accent2: #243232ff;
  --bg-accent3: #2e5252ff;
  --text-color: #aebebeff;
  --bg-red1: #ee0527ff;
  --bg-red2: #e0012aff;
  --bg-red3: #e5383b;
  --nav-size: 60px;
  --border-radius: 8px;
  --speed: 500ms;
  --bg-2: #3f646dff;
  --oxford-blue: #101730ff;
  --deep-space-sparkle: #3f646dff;
  --dark-slate-gray: #2e5252ff;
  --outer-space-crayola: #243232ff;
  --dark-jungle-green: #1b2524ff;
  /* CSS HEX */
  --medium-candy-apple-red: #e0012aff;
  --red-pigment: #ee0527ff;
  --old-mauve: #5c3145ff;
  --dark-purple: #140b2dff;
  --space-cadet: #212c50ff;
  /* CSS HEX */
  --cg-blue: #19819fff;
  --outer-space-crayola: #263434ff;
  --sonic-silver: #787170ff;
  --ash-gray: #aebebeff;
  --blue-sapphire: #025b79ff;
  --weirdgray: #242a36;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

h1 {
  color: var(--text-color);
}

h6 {
  text-shadow: 0 1px 0 #161a1d, 0 2px 0 #161a1d, 0 3px 0 #161a1d,
    0 4px 0 #161a1d, 0 5px 0 #161a1d, 0 6px 0 #161a1d,
    0 20px 30px rgba(0, 0, 0, 0.5);
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 0px) {
  .navbar {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: bgchange 15s ease infinite;

    height: var(--nav-size);
   
    padding: 0 1rem;
    border: 1px solid var(--ash-gray);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    overflow: hidden;
    top: 0;
    width: auto;
    border-radius: 20px;
    margin: 0px;

  }
  .navbar:hover {
    border: 1px solid var(--bg-accent2);
  }
  .navbar-nav {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    
  }
  .nav-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .icon-button {
    --button-size: calc(var(--nav-size) * 0.35);
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--blue-sapphire);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 300ms;
    transition: background-color red 2s;
    transition: transform 10s;
  }
  .icon-button:hover {
    filter: brightness(1);

    transform: rotate(30deg);
  }
  .icon-bg {
    --button-size: calc(var(--nav-size) * 0.4);
    width: calc(var(--button-size) * 1.2);
    height: calc(var(--button-size) * 1.2);
    background-color: var(--cg-blue);

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px inset var(--ash-gray);
    transition: filter 300ms;
    transition-timing-function: ease-out;
  }
  .icon-bg:hover {
    filter: brightness(1.2);
    background-color: transparent;
  }
  .icon-button svg {
    fill: var(--text-color);
    width: 48px;
    height: 48px;
  }
  .dropdown {
    position: absolute;
    top: 58px;
    width: 300px;
    transform: translate(-45%);
    background-color: var(--bg-2);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
  }
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
  }
  .menu-item:hover {
    background-color: var(--text-color);
  }
  .icon-right {
    margin-left: auto;
  }
  .menu {
    width: 100%;
  }
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }
  .navbar-2 {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
    border-radius: 20px;
    border: 1px solid var(--ash-gray);
    top: 0;
    background: linear-gradient(
      90deg,
      var(--bg),
      var(--bg-2),
      var(--bg-accent3),
      var(--bg-accent2),
      var(--weirdgray),
      var(--weirdgray),
      var(--ash-gray),
      var(--blue-sapphire),
      var(--dark-slate-gray),
      var(--bg-accent3),
      var(--bg-accent2),
      var(--bg-accent2),
      var(--bg-accent),
      var(--bg)
    );
    background-size: 1000%;
    animation: bgchange 30s ease infinite;
    width: auto;
    margin: 5px;
  }
  .navbar-2:hover {
    border: 1px solid var(--bg-accent2);
  }
  .navbar-nav-under-text {
    color: white;
    margin: 5px;
    padding: 5px;
    font-size: medium;
    font-family: 'Exo', sans-serif;
  }
  .navbar-nav-under-text:hover {
    color: #ffffff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbar {
    height: var(--nav-size);
    background-color: var(--weirdgray);
    padding: 0 1rem;

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: auto;
    border-radius: 20px;
    border: 1px solid var(--ash-gray);
    margin: 2px;
  }
  .navbar:hover {
    border: 1px solid var(--bg-accent2);
  }
  .navbar-nav {
    max-width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .nav-item {
    width: calc(var(--nav-size) * 2.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-button {
    --button-size: calc(var(--nav-size) * 0.8);
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--blue-sapphire);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 300ms;
    transition: background-color var(--ash-gray) 2s;
    transition: transform 10s;
  }
  .icon-button:hover {
    filter: brightness(1);

    transform: rotate(30deg);
  }
  .icon-bg {
    --button-size: calc(var(--nav-size) * 1);
    width: calc(var(--button-size) * 1);
    height: calc(var(--button-size) * 1);
    background-color: var(--cg-blue);

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px inset var(--ash-gray);
    transition: filter 300ms;
    transition-timing-function: ease-out;
  }
  .icon-bg:hover {
    filter: brightness(1.2);
  }
  .icon-button svg {
    fill: var(--text-color);
    width: 48px;
    height: 48px;
  }
  .dropdown {
    position: absolute;
    top: 58px;
    width: 300px;
    transform: translate(-45%);
    background-color: var(--bg-2);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
  }
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
  }
  .menu-item:hover {
    background-color: #660708;
  }
  .icon-right {
    margin-left: auto;
  }
  .menu {
    width: 100%;
  }
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }
  .navbar-2 {
    height: var(--nav-size);
    background-color: var(--dark-jungle-green);
    padding: 0 1rem;
    border-bottom: var(--border);
    border: 1px solid var(--ash-gray);
    top: 0;
    background: linear-gradient(
      90deg,
      var(--bg),
      var(--bg-2),
      var(--bg-accent3),
      var(--bg-accent2),
      var(--bg-red1),
      var(--bg-red1),
      var(--bg-red2),
      var(--blue-sapphire),
      var(--dark-slate-gray),
      var(--bg-accent3),
      var(--bg-accent2),
      var(--bg-accent2),
      var(--bg-accent),
      var(--bg)
    );
    background-size: 1000%;
    animation: bgchange 30s ease infinite;
    width: auto;
    border-radius: 20px;
    margin: 5px;
  }
  .navbar-2:hover {
    border: 1px solid var(--bg-accent2);
  }
  .nav-item-li {
    width: calc(var(--nav-size) * 1);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .navbar-nav-under-text {
    color: var(--ash-gray);
    font-size: x-large;
    font-family: 'Exo', sans-serif;
    transition: filter 300ms;
    transition-timing-function: ease-out;
    filter: brightness(0.5);
  }
  .navbar-nav-under-text:hover {
    color: #ffffff;
    transition: filter 300ms;
    transition-timing-function: ease-out;
    filter: brightness(1);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 800px) {
  .navbar {
    height: var(--nav-size);
    background-color: var(--weirdgray);
    padding: 0 1rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: auto;
    margin: 5px;
    border-radius: 20px;
    border: 1px solid var(--ash-gray);
  }
  .navbar:hover {
    border: 1px solid var(--bg-accent2);
  }
  .navbar-nav {
    max-width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .nav-item {
    width: calc(var(--nav-size) * 4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-button {
    --button-size: calc(var(--nav-size) * 1);
    width: var(--button-size);
    height: var(--button-size);
    background-color: var(--blue-sapphire);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter 300ms;
    transition: background-color red 2s;
    transition: transform 10s;
  }
  .icon-button:hover {
    filter: brightness(1);

    transform: rotate(30deg);
  }
  .icon-bg {
    --button-size: calc(var(--nav-size) * 2);
    width: calc(var(--button-size) * 0.6);
    height: calc(var(--button-size) * 0.6);
    background-color: var(--cg-blue);

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px inset var(--ash-gray);
    transition: filter 300ms;
    transition-timing-function: ease-out;
  }
  .icon-bg:hover {
    filter: brightness(1.2);
  }
  .icon-button svg {
    fill: var(--text-color);
    width: 48px;
    height: 48px;
  }
  .dropdown {
    position: absolute;
    top: 58px;
    width: 300px;
    transform: translate(-45%);
    background-color: var(--bg-2);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
  }
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
  }
  .menu-item:hover {
    background-color: #660708;
  }
  .icon-right {
    margin-left: auto;
  }
  .menu {
    width: 100%;
  }
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }

  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }

  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }
  .navbar-2 {
    height: var(--nav-size);
    background: linear-gradient(
      90deg,
      var(--bg),
      var(--bg-2),
      var(--bg-accent3),
      var(--bg-accent2),
      var(--weirdgray),
      var(--weirdgray),
      var(--ash-gray),
      var(--blue-sapphire),
      var(--dark-slate-gray),
      var(--bg-accent3),
      var(--bg-accent2),
      var(--bg-accent2),
      var(--bg-accent),
      var(--bg)
    );
    padding: 0 1rem;
    margin: 5px;
    border: 1px solid var(--ash-gray);
    top: 0;
    background-size: 1000%;
    animation: bgchange 30s ease infinite;
    width: auto;
    border-radius: 20px;
    border: 1px solid var(--ash-gray);
  }
  .navbar-2:hover {
    border: 1px solid var(--bg-accent2);
  }
  .nav-item-li {
    width: calc(var(--nav-size) * 13);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .navbar-nav-under-text {
    color: var(--ash-gray);
    font-size: large;
    font-family: 'Exo', sans-serif;
  }
  .navbar-nav-under-text:hover {
    color: #ffffff;
  }
}

@media only screen and (min-width: 1000px) {
  .nav-item {
    width: calc(var(--nav-size) * 6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes bgchange {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(65deg);
  }
  
  75% {
    transform: rotate(105deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ghost {
  0% {
    transform: translate(0px, 0px) rotate(-10deg);
    
  }
  25% {
    transform: translate(-10px, -30px) rotate(30deg);
    
  }
  50% {
    transform: translate(0px, -22px) rotate(10deg);
   
  }
  75% {
    transform: translate(-10px, -5px) rotate(-6deg);
 
  }
  80% {
    transform: translate(-5px, -3px) rotate(5deg);

  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes turkey {
  0% {
    transform: translate(0px, 0px) ;
    
  }
  5% {
    transform: translate(-2px, -2px) ;
    
  }
  10% {
    transform: translate(-4px, 0px) ;
   
  }
  75% {
    transform: translate(-4px, 0px) ;
 
  }
  80% {
    transform: translate(-10px, 0px) ;

  }
  100% {
    transform: translate(-30px, 0px) ;
  }
}

@keyframes leaf {
  0% {
    transform: translate(0px, 0px) ;
    
  }
  25% {
    transform: translate(2px, 10px) rotate(40deg) ;
    
  }
  
  100% {
    transform: translate(200px, 600px)rotate(0deg) ;
  }
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(36, 42, 54, 1);
    border-radius: 50px;
    
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(36, 42, 54, 0);
    border-radius: 50px;
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(238, 5, 39, 0);
    border-radius: 50px;
    color: red;
  }
}

@keyframes coffeePulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    border-radius: 50px;
    
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    border-radius: 50px;
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    border-radius: 50px;
    color: white;
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(36, 42, 54, 1);
    border-radius: 50px;
    border-radius: 5px;
    
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(36, 42, 54, 0);
    border-radius: 50px;
    
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(238, 5, 39, 0);
    border-radius: 50px;
    border-radius: 5px;
    border: 1px solid #ee0527ff;
  }
}

@keyframes pulseHaloween {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(230, 126, 34, 1);
    border-radius: 50px;
    border-radius: 5px;
    
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(230, 126, 34, 0);
    border-radius: 50px;
    
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(230, 126, 34, 0);
    border-radius: 50px;
    border-radius: 5px;
    border: 1px solid orange;
  }
}

@keyframes pulseThanksgiving {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(114, 61, 70, 1);
    border-radius: 50px;
    border-radius: 5px;
    
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(114, 61, 70, 0);
    border-radius: 50px;
    
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(114, 61, 70, 0);
    border-radius: 50px;
    border-radius: 5px;
    border: 1px solid orange;
  }
}

@keyframes pumkin {
  0% {
    transform: scale(3);
    box-shadow: 0 0 0 0 rgba(230, 126, 34, 1);
    border-radius: 50px;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(230, 126, 34, .5);
    border-radius: 50px;
  }
    
  100% {
    transform: scale(3);
    box-shadow: 0 0 0 0 rgba(230, 126, 34, 1);
   
    border-radius: 50px;
  }
}

@keyframes football {
  0% {
    transform: scale(3);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 10px;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, .5);
    border-radius: 10px;
  }
    
  100% {
    transform: scale(3);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
   
    border-radius: 10px;
  }
}