.tablethingdiv2{
    overflow-x:auto;
    width: 100%;
    height: 100%;
}
.giotable > th:nth-child(odd):hover{
    color:#0353a4;
    border: 1px solid white;
}
.giotable > th:nth-child(even):hover{
    color:#0353a4;
    border: 1px solid white;
}
.giotable > td:nth-child(even):hover{
    color:#0353a4;
    border: 1px solid white;
}
.giotable > td:nth-child(odd):hover{
    color:#0353a4;
    border: 1px solid white;
}

.giotable > th{
    background-color: #0466c8;
    border-top:none;
    font-size: large;
    border: 1px solid #001845;
   
}
.giotable > td,th{
   
    border-top: 1px solid #001233;
}

.giotable > td{
    border-top: 1px solid #33415c;
    border-right:1px solid rgb(0, 0, 0);
}
.giotable-table{
    color: #0466c8;
    background-color: #001233;
    border: 2px solid #001845;
    border-collapse: collapse;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: auto;
    white-space: nowrap;
    border-radius: 4px;
    border-spacing: 0px;
    text-align: left;
    width: 100%;
    height: 100%;
    overflow-x:auto;  
}

.giotable > tr:nth-child(even){
    background-color: #001233;
}
.giotable > tr:nth-child(odd){
    background-color: #33415c;
}
.giotable > th:nth-child(odd){
    color: #979dac;
}
.giotable > th:nth-child(even){
    color: #002855;
}

.giotable > tr:hover{
    background-color: #979dac;
    color: #002855;
    box-shadow: #0353a4 0px 0px 9px 0px;
}
.giotable > tr{
    box-shadow: 0px 0px 9px 0px;
}
