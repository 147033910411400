.App {
  text-align: center;
  background-color: transparent;
 
}

.modalTitle{
  font-weight:900;
  letter-spacing:10px;
  align-items: center;
  align-self: center;
  color: #19819Fff;
  margin-top: 10px;
  animation: fadeIn 6s linear;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 20px;
}

.modalBox{
  background-color: rgba(36, 50, 50, 0.597);
  width:100%;
  border: 1px solid #19819Fff;
  margin-top: 10px;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  animation: fadeIn 8s linear;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.modalBox:hover{
  background-color: rgba(0, 0, 0, 0.597);
  border: 1px solid white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.modalbtnspecial{
 display:none;
}

.modalbtn{
  position: fixed; /* Sit on top of the page content */
  display: sticky; /* Hidden by default */
  width: 25%; /* Full width (cover the whole page) */
  height: 5%; /* Full height (cover the whole page) */
  textAlign: center; 
  bottom: 0;
  opacity: .6;
  background-color: #242a364f; 
  --borderWidth: 5px;
  color: #19819Fff;
  z-index: 9999;

  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
  font-weight: 9002;
}
.modalbtn:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #101730ff, #1B2524ff, #101730ff, #243232ff, #2E5252ff, #242a36, #19819Fff, #025B79ff);
  z-index: -1;
  animation: animatedgradient 2s ease alternate infinite;
  background-size: 600% 300%;
  border: 3px solid ;
  border-radius: 5px;
}
.modalbtn:hover{
  background-color: rgba(20, 11, 45, 0.175); /* Black background with opacity */
  animation:none;
  border: 1px solid blue;
  opacity: 1;
}
.modalOn{
  
  padding-top: 150px;
  transition: padding 1s ease-in;
  
}
.modalOff{
  padding-top: 0px;
  transition: padding 2s ease-out;
  animation: fadeIn 1s linear alternate;
  
}
.modal {
  display: none; /* Hidden by default */
  flex-direction: column;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.936); /* Black w/ opacity */
  animation: fadeIn 2s linear alternate;
  animation: blowUpContent 1s  linear ;
  
}
.modalbtninner{
  position: relative; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 25%; /* Full height (cover the whole page) */
  padding: 0px;
  margin: 0px;
  border-radius: 20px;
  letter-spacing:5px;
  background-color: transparent; /* Fallback color */ 
  color: white;
  border:none;
  cursor: pointer;
  align-items: center;
  align-self: center;
  animation: fadeIn 4s linear;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  
}
.modalbtninner:hover{
  border: none;
  background-color: white;
  color: black; 

}


/* Modal Content/Box */
.modal-content {
  background-color: rgba(0, 0, 0, 0.669);
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #19819Fff;
  border-radius: 20px;
  width: 80%; /* Could be more or less, depending on screen size */
  align-items: center;
  content: '';
  background: linear-gradient(60deg, #101730ff, #1B2524ff, #101730ff, #243232ff, #2E5252ff, #242a36, #19819Fff, #025B79ff);
  border-radius: calc(2 * var(--borderWidth));
  animation: animatedgradient 30s ease infinite alternate; ;
  background-size: 600% 600%;

}
.modal-content:hover{
  border: 1px solid black;
  background: transparent;
}



.listmodal{
  color:white;
  padding-left: 15px;
  padding: 10px;
  letter-spacing:5px;
  margin: 10px;
 
}
.listmodal:nth-child(1):hover{
  background:none;
   
 
}
.listmodal:hover{
  content: '';
  background: linear-gradient(60deg,  #19819Fff, #025B79ff);
  border-radius: 30px;
  animation: animatedgradient 2s ease infinite alternate; ;
  background-size: 150% 200%;
  color: #FFFFFF;
  
  
}




.DrawerBox{
  position:absolute; 
  top:0px;
  right: 0px;
  left: 60px;
  width: 19vw;
  height: 5vh;
  /* Center child horizontally*/
  display: flex;
  justify-content: center;
  padding: 10px;
}
.DrawerBoxElement{
  
  
}

.MuiButton-root {
  color: #20b2aa;
}

iframe:hover {
 color:black;
}

input{
  color: white;
  background-color: black;
  padding: 10px;
  margin: 10px;
}

label{
  padding: 20px;
  margin: 10px;
  font-size: auto;
  transition: 4s;
}



input[type=text] {
  width: 66vw;
  font-size: auto;
  animation: pulse 5s infinite;
}

input[type=text]:hover {
  width: 66vw;
  border: 5px solid white;
  border-radius: 20px;
  animation: animatedgradient2 2s infinite;
}

input[type=text]:focus-visible {
  width: 60vw;
  color: var(--bg-red2);
}

h6{
  color: #1B2524ff;
}

#canvas-container{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: "#3F646Dff";
}
#canvas-container2{
  position: absolute;
  width: 100%;
  height: 100%;
  
}
#bg{
  position: fixed;
  top: 0;
  left: 0;
}


@media screen and (max-height: 460px){
  .modalbtn{
    
    padding-top: 7px;
    width: 10%; 
    height: 10%;
  }
  .App {
    text-align: center;
    background-color: #1B2524ff;
  
  }
}


@media screen and (max-height: 360px){

  .modalbtn{
    
    padding-top: 7px;
    width: 10%; 
    height: 10%;
  }
  .App {
    text-align: center;
    background-color: #1B2524ff;
  
  }
}

@media screen and (max-width: 360px){
  .modalbtn{
    padding-top: 7px;
    width: 10%; 
    height: 10%;
  }

  .App {
    text-align: center;
    background-color: #1B2524ff;
  
  }
  #canvas-container{
    position: absolute;
    width: 100%;
    height: 95%;
    background-color: "#3F646Dff";
  }
  #canvas-container2{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  #bg{
    position: fixed;
    top: 0;
    left: 0;
  }
}

@-webkit-keyframes fadeIn { 
  0% { opacity: 0; }
  20% { opacity: 0; }
  40% { opacity: 0.3; }
  60% { opacity: 0.5; }
  80% { opacity: 0.9; }
  100% { opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  20% { opacity: 0; }
  40% { opacity: 0.3; }
  60% { opacity: 0.5; }
  80% { opacity: 0.9; }
  100% { opacity: 1; }
}

@keyframes blowUpContent {
  0% {
    transform:scale(4);
    opacity:0;
  }
  25% {
    transform:scale(3);
    opacity:.5;
  }
  50% {
    transform:scale(2);
    opacity:1;
  }
  100% {
    transform:scale(1);
  }
}

@keyframes blowUpContentTwo {
  0% {
    transform:scale(2);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:1;
  }
}

@keyframes blowUpModal {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes blowUpModalTwo {
  0% {
    transform:scale(1);
    opacity:1;
  }
  100% {
    transform:scale(0);
    opacity:0;
  }
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes animatedgradient2 {
	0% {
		color:white;
	}
	50% {
		color:gray;
	}
	100% {
		color:white;
	}
}


@-moz-keyframes spin { 
  100% { -moz-transform: rotate(30deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(30deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(30deg); 
      transform:rotate(30deg); 
  } 
}


@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(36, 42, 54, 1);
    border-radius: 50px;
    
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(36, 42, 54, 0);
    border-radius: 50px;
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(238, 5, 39, 0);
    border-radius: 50px;
    
  }
}
